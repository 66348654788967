
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import NavigationMixin from '@/components/mixins/navigation.mixin';

@Component
export default class BreadCrumbs extends mixins(NavigationMixin) {
  @Prop({ type: String, default: '»' }) separator!: string;

  isLast(index: number): boolean {
    return index === this.links.length - 1;
  }
  isFirst(index: number): boolean {
    return index === 0;
  }
}
