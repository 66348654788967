
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import keyBy from 'lodash/keyBy';
import { Location } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { AUTH_SRC } from '@/constants';
import { openErrorSnackbar, openSnackbar, openWarningSnackbar } from '@/utils/components';
import GAnalytics from '@/components/GAnalytics';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import { FormField } from '@/components/form/form.types';
import MaxWidth from '@/components/MaxWidth.vue';
import FormWizard from '@/components/form/FormWizard.vue';
import {
  addApiRegistrationVersion,
  getApiRegistration,
  registerApi,
  updateApiRegistration,
  validateProxyPath,
  validateResourceName,
} from '@/api/registration.api';
import { RouteNames } from '@/constants';
import PreventBack from '@/components/form/PreventBack.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import { UINavigationItem } from '@/types';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import {
  converters,
  getParsedValue,
  getValue,
  makeFormWizardData,
  populateAutocompleteChoices,
  normalizeUrlPath,
  prepareFormData,
  resolveProxyPath,
} from '@/views/api-registration/registration.helpers';
import { EnumsModule } from '@/store/modules/enums.module';
import { AppEnum, ensureAppEnum, Status } from '@/types/enum.types';
import {
  ApiRegistration,
  ApiRegistrationFormData,
  FieldKeys,
  DEFAULT_PAYLOAD_SIZE,
  DEFAULT_PAYLOAD_UNIT,
} from '@/types/registration.types';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { runCustomValidators } from '@/components/form/utils';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { randId } from '@/utils/components';
import { kongApiKeyIdDefaultValue } from '@/constants';
import URLs, {safeUrl, safeUrlRevert} from '@/api/service/urls';
import * as API from '@/api/projects.api';

type VObs = InstanceType<typeof ValidationObserver>;
@Component({
  components: {
    FormWizard,
    GButton,
    GTextfield,
    GAnalytics,
    GRadioGroup,
    MaxWidth,
    ValidationObserver,
    PreventBack,
    BreadCrumbs,
    ValidatedForm,
  },
})
export default class ApiRegistrationView extends Vue {
  disablePreventBack = false;
  loading = false;
  loadingRegistration = false;
  formFields: FormField<FieldKeys>[] = [];
  serverErrors: Partial<Record<FieldKeys, string>> = {};
  headers: { headerName: string; headerValue: string }[] = [];

  $refs!: {
    validationObserver: VObs;
  };

  get crumbs(): UINavigationItem[] {
    let name = EnumsModule.enums.environment.DEV.name;
    let pName = ProjectDetailsModule.projectDetails.projectName || 'Project';

    if (this.isEdit) {
      // new versions and original regs only happen in dev
      name = this.envName;
    }
    return [
      {
        key: 'project',
        text: this.envName ? `${pName} ${name} Environment` : pName,
        route: this.projectRoute,
      },
      {
        key: 'environment',
        text: 'Registrations',
        route: this.projectRoute,
      },
      {
        key: 'registration',
        text: this.registrationCrumbText,
        route: { ...this.$route, name: this.$route.name ?? '' },
      },
    ];
  }
  get registrationCrumbText(): string {
    if (this.isEdit) {
      return 'Edit Registration';
    }
    if (this.isVersion) {
      return 'Register New API Version';
    }

    return 'Register New API';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  populateField(key: FieldKeys, value: any, attrs?: FormField['attrs']) {
    const field = this.fields[key];
    if (field) {
      if (value === undefined) {
        // accounts for old preloaded registrations
        // use default value
        value = field.value;
      }
      field.value = value ?? '';
      if (attrs) {
        if (field.attrs) {
          field.attrs = { ...field.attrs, ...attrs };
        } else {
          field.attrs = attrs;
        }
      }
    } else {
      this.$log('warn', `field "${key}" was not found in form: ${Object.keys(this.fields)}`);
    }
  }

  get oauthMnemonic() {
    const projectId = +this.$route.params.id;
    const projectEnvId = +this.$route.params.env;
    if (
      ProjectDetailsModule.oAuthMneumonics[projectId] &&
      ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType
    ) {

      return ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType;
    }
    else if(ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType){
        return ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType;
    }

    return 'API_KEY';
  }

  populateFields(reg: ApiRegistration) {
    const pf = this.populateField;
    const k = FieldKeys;

    if (this.isEdit || this.isVersion) {
      pf(k.name, reg.resourceName, { disabled: 'true' });
      pf(k.description, reg.resourceDescription, { disabled: 'true' });

      pf(k.proxyPath, converters.toClient[k.proxyPath](reg));
      pf(k.authType, converters.toClient[k.authType](null, reg));
      pf(k.timeout, reg.timeoutMs);
      pf(k.compliant, reg.isControlsCompliant);
      pf(k.approval, reg.requiresApproval);
      pf(k.notify, reg.requiresNotification);
      pf(k.httpMethods, converters.toClient[k.httpMethods](reg));
      if (this.isEdit) {
        pf(k.customNotes, reg.customNotes);
        pf(k.upstreamUrl, reg.upstreamUrl);
        pf(k.version, reg.versionId.toString());
        pf(k.headerName, reg.headerName);
        pf(k.headerValue, reg.headerValue);
        pf(k.corsSupport, Boolean(reg.corsSupport));
        pf(k.headers, converters.toClient[k.headers](reg));
        pf(k.payloadSize, reg.requestPayloadSize || DEFAULT_PAYLOAD_SIZE);
        pf(k.payloadUnits, reg.requestPayloadUnit || DEFAULT_PAYLOAD_UNIT);
        pf(k.payloadJustification, reg.requestPayloadBusinessJustification);
        pf(k.payloadSizeEnabled, reg.requestPayloadBusinessJustification !== null);
        pf(
          k.queryParams,
          reg.upstreamQueryString?.map(kv => {
            return { ...kv, id: randId() };
          }),
        );
        pf(k.ipRestrictionEnabled, reg.serviceProperties.enabled_ip_restriction_plugin === 1);
        if (reg.serviceProperties.enabled_ip_restriction_plugin === 1) {
          pf(k.allowedIPList, reg.serviceProperties.allowed_ip_list);
        }
        const apiKeyIdentifiers = reg.serviceProperties?.kong_keyauth_template_apikeyidentifier;
        if (Array.isArray(apiKeyIdentifiers) && apiKeyIdentifiers.toString() !== kongApiKeyIdDefaultValue) {
          pf(k.apiKeyEnabled, !!reg.serviceProperties?.kong_keyauth_template_apikeyidentifier);
          pf(k.apiKeyIdentifier, reg.serviceProperties?.kong_keyauth_template_apikeyidentifier?.join(','));
        }
      }
      if (FeatureFlagsModule.featureFlags.HEALTHCHECK.featureFlagEnabled) {
        pf(k.healthCheckEnabled, !!reg.healthCheckPath);
        pf(k.healthCheckPath, reg.healthCheckPath);
        pf(k.healthCheckPattern, reg.healthCheckPattern);
      }
    }
  }

  get env(): AppEnum {
    const penvId = this.$route.params.env;
    const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[penvId]?.environmentId ?? -1;
    return ensureAppEnum(EnumsModule.enumsById.environment[envId]);
  }

  get envName(): string {
    return this.env.name;
  }

  service: ApiRegistration | null = null;
  async created() {
    const projectId = +this.$route.params.id;
    const projectEnvId = +this.$route.params.env;
    const serviceId = +this.$route.params.serviceId;
    const fromEnv = this.$store.state?.route?.from?.name === RouteNames.ProjectEnvDetails;

    if (!fromEnv) {
      await Promise.all([
        ProjectDetailsModule.getProject(projectId),
        ProjectDetailsModule.getProjectEnvironmentPart({
          projectId,
          projectEnvId,
          partName: 'auth',
        }),
      ]);
    }
    /*if (this.isEdit) {
      this.loading = true;
      await getHeaders(serviceId)
        .then(response => {
          const { data } = response;
          this.headers = data.headers?.map(
            (header: { headerSecretName: string; headerSecretValue: string }) => {
              return { headerName: header.headerSecretName, headerValue: header.headerSecretValue };
            },
          );
        })
        .catch(() => {
          this.$logger.error('Error on header retrieval for service Id ' + serviceId);
        })
        .finally(() => {
          this.loading = false;
          return [];
        });
    }*/
    // can only create version from Dev service, otherwise API throws, and i didn't feel like
    // looking up the dev service id of the qa / prod service and rerouting
    if (this.isVersion) {
      const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[projectEnvId].environmentId;
      const isDev = EnumsModule.enums.environment.DEV.id === envId;
      if (!isDev) {
        openWarningSnackbar.call(
          this,
          'You can only create a new version from a Dev environment API',
        );
        this.disablePreventBack = true;
        return this.$router.replace(this.projectRoute);
      }
    }
    if (this.isEdit || this.isVersion) {
      const oAuthType = ProjectDetailsModule.envs[projectId][projectEnvId].auth.OAuth.oauthType;
      ProjectDetailsModule.setOAuthMneumonic({
        projectId,
        projectEnvId,
        oAuthType
      })
      this.loading = true;
      const regs = ProjectDetailsModule.getProjectEnvironmentPart({
        projectId,
        projectEnvId,
        partName: 'registrations',
      });
      getApiRegistration(serviceId)
        .catch(() => {
          openErrorSnackbar.call(this, 'Server Error: Failed to load service definition');
          this.disablePreventBack = true;
          this.$router.replace(this.projectRoute);
          return { data: null };
        })
        .then(async ({ data }) => {
          if (!data) {
            return;
          }

          // it's being stringified on the server for some reason idk don't feel like looking
          const pp = data.serviceProperties.kong_proxy_path as string | [string];
          if (typeof pp === 'string') {
            try {
              data.serviceProperties.kong_proxy_path = JSON.parse(pp);
            } catch (e) {
              this.$log('warn', 'kong_proxy_path is unexpectedly a string');
            }
          }

          await regs;
          const versions =
            ProjectDetailsModule.envs[projectId][projectEnvId]?.registrations?.services
              .find(ss => ss.some(s => s.registrationVersionId === data.registrationVersionId))
              ?.filter(s =>
                this.isEdit ? s.registrationVersionId !== data.registrationVersionId : true,
              )
              .map(s => s.version) ?? [];
          this.service = data;
          const serviceStatus = EnumsModule.enumsById.status[data.statusId];

          // can't edit an api that is not fully provisioned, per chris in the last meeting
          if (this.isEdit && serviceStatus.mnemonic === 'PROVISIONING') {
            openWarningSnackbar.call(
              this,
              'Wait until this API is fully provisioned before editing it',
            );
            this.disablePreventBack = true;
            return this.$router.replace(this.projectRoute);
          }
          this.formFields = makeFormWizardData({
            isEdit: this.isEdit,
            isVersion: this.isVersion,
            versions,
            oauthMnemonic: this.oauthMnemonic,
            proxyValidator: this.validateProxyPath,
            resourceNameValidator: this.validateResourceName,
          });

          const autocompleteFields = [];
          if (FeatureFlagsModule.ciIdPerServiceEnabled) {
            if (data.serviceNowId) {
              autocompleteFields.push({
                fieldKey: FieldKeys.serviceNowId,
                promise: API.getServiceNowIds(String(data.serviceNowId)).then(res => res.data),
                formatter: (choice: any) => ({
                  label: `${choice.id} - ${choice.name}`,
                  value: choice.id,
                }),
                selectedKey: String(data.serviceNowId),
              });
            } else {
              // if service does not have a serviceNowId, enable the field.
              const serviceNowIdField = this.formFields.find(
                (field) => field.key === FieldKeys.serviceNowId,
              );
              if (serviceNowIdField && serviceNowIdField.attrs && 'disabled' in serviceNowIdField.attrs) {
                serviceNowIdField.attrs.disabled = false;
              }
            }
          }

          await populateAutocompleteChoices(this.formFields, autocompleteFields);
          this.populateFields(data);
          this.$log(JSON.stringify(data, null, 2));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
        this.formFields = makeFormWizardData({
          isEdit: this.isEdit,
          isVersion: this.isVersion,
          oauthMnemonic: this.oauthMnemonic,
          proxyValidator: this.validateProxyPath,
          resourceNameValidator: this.validateResourceName,
        });
    }
  }

  mounted() {
    // this is only hear because the router level scroll reset isn't working for this page
    this.$nextTick(() => {
      (document.querySelector('#layout-main') || { scrollTop: 0 }).scrollTop = 0;
    });
  }

  get isEdit() {
    return this.$route.name === RouteNames.RegisterApiEdit;
  }

  get isVersion() {
    return this.$route.name === RouteNames.RegisterApiVersion;
  }

  get heading() {
    if (this.isEdit || this.isVersion) {
      if (this.service?.resourceName) {
        if (this.isVersion) {
          return this.service.resourceName;
        }
        return `${this.service.resourceName} v${this.service.versionId}`;
      }
      if (this.isVersion) {
        return 'Register New API Version';
      }
      return 'Edit API Registration';
    }
    return 'Register New API';
  }

  get fields() {
    const allFields = this.formFields;
    return keyBy(allFields, f => f.key) as Record<FieldKeys, FormField<FieldKeys>>;
  }

  get serviceProperties(): ApiRegistrationFormData['serviceProperties'] {
    const k = FieldKeys;
    return {
      [k.proxyPath]: converters.toServer[k.proxyPath](this.fields),
      timeout_ms: getValue(this.fields.timeoutMs),
      enabled_ip_restriction_plugin: getParsedValue(this.fields.allowedIPList).length ? 1 : 0,
      allowed_ip_list: getParsedValue(this.fields.allowedIPList),
      kong_keyauth_template_apikeyidentifier: getParsedValue(this.fields.apiKeyIdentifier),
    };
  }

  get formData(): ApiRegistrationFormData {
    if (this.isEdit && this.service) {
      return prepareFormData(this.fields, this.service);
    }
    return prepareFormData(this.fields);
  }

  get projectRoute(): Location {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: {
        ...this.$route.params,
        section: 'registrations',
      },
      query: {},
    };
  }

  validateProxyPath(path: string): Promise<string> {
    const env = this.env.mnemonic;
    const newPath = normalizeUrlPath(path, true);
    if (this.service && this.isEdit) {
      const isSameEnvironment =
        this.service.environmentId === EnumsModule.enums.environment[env].id;
      const servicePath = normalizeUrlPath(
        resolveProxyPath(this.service.serviceProperties.kong_proxy_path),
        true,
      );
      const isSamePath = servicePath === newPath;
      if (isSameEnvironment && isSamePath) {
        return Promise.resolve('');
      }
    }
    return validateProxyPath(newPath, env).then(r =>
      r.data ? '' : 'This proxy path is already in use by another API',
    );
  }

  validateResourceName(resourceName: string): Promise<string> {
    this.$log('validating resource name');
    const env = this.env.mnemonic;
    if (this.service && this.isEdit) {
      const isSameEnvironment =
        this.service.environmentId === EnumsModule.enums.environment[env].id;
      if (isSameEnvironment && resourceName === this.service.resourceName) {
        return Promise.resolve('');
      }
    }
    if(resourceName.trim().length>0)
    {
      return validateResourceName(resourceName, env).then(r =>
        r.data ? '' : 'This registration name is already in use by another project',
      );
    }
    else
      return Promise.resolve('');
  }

  async runCustomValidators() {
    const res = await runCustomValidators(this.formFields);
    if (res.hasErr) {
      this.serverErrors = res.serverErrors;
      const err = Object.entries(res.serverErrors)[0];
      if (err) {
        const [key] = err;
        this.scrollToField(key);
      }
    }
    return res.hasErr;
  }

  scrollToField(key: string) {
    const el = document.getElementById(key);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      // el.focus();
    }
  }

  async register(): Promise<void> {
    this.loadingRegistration = true;
    let reg: ApiRegistration | null = null;

    const vo: VObs = this.$refs.validationObserver;
    const isValid: boolean = await vo.validate();
    if (isValid) {
      const hasErr = await this.runCustomValidators();
      if (hasErr) {
        this.loadingRegistration = false;
        return;
      }
    } else {
      for (const formField of this.formFields) {
        const { key } = formField;
        const err = vo.errors[key];
        if (err) {
          this.scrollToField(key);
          break;
        }
      }
      this.loadingRegistration = false;
      return;
    }

    this.disablePreventBack = true;
    try {
      // update upstream to avert firewall issues
      this.formData.upstreamUrl = safeUrl(this.formData.upstreamUrl);
      if (this.isEdit) {
        reg = await this.editRegistration()
          .then(r => r.data)
          .finally(() => (this.loadingRegistration = false));
      } else if (this.isVersion) {
        reg = await addApiRegistrationVersion({
          serviceId: this.$route.params.serviceId,
          versionId: this.formData.versionId,
          data: { service: this.formData },
        }).then(r => r.data);
      } else {
        reg = await registerApi({
          projectId: this.$route.params.id,
          data: { service: this.formData },
        })
          .then(r => r.data)
          .finally(() => (this.loadingRegistration = false));
      }
    } catch (e) {
      this.disablePreventBack = false;
      this.$log('error', e);
      openSnackbar.call(this, `Server Error: API Registration Failed - ${(e as unknown as Error)?.message || ''}`, {
        type: 'error',
      });
      this.formData.upstreamUrl = safeUrlRevert(this.formData.upstreamUrl);
    }
    if (reg) {
      const r = this.projectRoute;
      if (reg.statusMnemonic === Status.Provisioning) {
        r.query = { ...(r.query ?? {}), success: '1', isCustom: Number(reg.isCustom).toString() };
      }
      this.$router.replace(r);
    }
  }
  async editRegistration() {
    return updateApiRegistration({
      serviceId: this.$route.params.serviceId,
      data: { service: this.formData },
    });
  }
}
