
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PreventBack extends Vue {
  @Prop({
    validator(value: unknown): boolean {
      try {
        JSON.stringify(value);
        return true;
      } catch (e) {
        return false;
      }
    },
  })
  value!: unknown;
  @Prop({ type: String, default: 'You have unsaved changes, are you sure you want to exit?' })
  message!: string;
  @Prop(Boolean) disabled!: boolean;
  @Prop(Boolean) force!: boolean;

  initialValue = JSON.stringify(this.value);
  hasUnloadHandler = false;

  @Watch('value', { deep: true })
  handleValueChange(v: unknown) {
    // if force is present then it's always-on independent of value
    if (this.disabled || this.force) {
      return;
    }
    if (JSON.stringify(v) !== this.initialValue) {
      this.addHandler();
    } else {
      this.removeHandler();
    }
  }

  @Watch('disabled')
  handleDisabled(disabled: boolean) {
    if (disabled) {
      this.removeHandler();
    } else {
      this.addHandler();
    }
  }

  @Watch('force', { immediate: true })
  handleForce(force: boolean) {
    if (this.disabled) {
      return;
    }
    if (force) {
      this.addHandler();
    } else if (this.value === this.initialValue) {
      this.removeHandler();
    } else {
      this.addHandler();
    }
  }

  addHandler() {
    if (!this.hasUnloadHandler && !this.disabled) {
      window.addEventListener('beforeunload', this.unloadHandler);
      if (this.$route.meta) {
        this.$route.meta.confirmExit = this.message;
      }
    }
  }

  removeHandler() {
    window.removeEventListener('beforeunload', this.unloadHandler);
    this.hasUnloadHandler = false;
    if (this.$route.meta) {
      this.$route.meta.confirmExit = '';
    }
  }

  unloadHandler(e: BeforeUnloadEvent) {
    e.returnValue = 'a';
  }

  beforeDestroy() {
    this.removeHandler();
  }
}
