
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import * as types from './form.types';
import NavigationList from '@/components/NavigationList.vue';
import { UITab } from '@/types';
import GButton from '@/components/gsk-components/GskButton.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { runCustomValidators } from '@/components/form/utils';

type VObs = InstanceType<typeof ValidationObserver>;

@Component({
  components: {
    ValidationObserver,
    ValidatedForm,
    NavigationList,
    GButton,
  },
})
export default class FormWizard extends Vue {
  @Prop({ type: Array, required: true }) value!: types.FormWizardStep[];
  @Prop({ type: String, default: 'Submit' }) submitButtonText!: string;
  @Prop({ type: String, default: 'Next' }) nextButtonText!: string;
  @Prop({ type: Object, default: () => ({}) }) serverErrors!: types.ServerErrors;
  @Prop(Boolean) hideSubmit!: boolean;
  @Prop(Boolean) hideNav!: boolean;
  @Prop(Boolean) showBack!: boolean;
  // lets you jump around the form without going through each step
  @Prop(Boolean) debug!: boolean;
  $refs!: {
    topObserver: VObs;
    observer: VObs | VObs[];
  };
  observersReady = false;

  @Watch('value', { deep: true })
  checkNav() {
    if (this.activeIndex === -1 && this.value[0]) {
      this.activeLink = this.value[0].nav;
      setTimeout(() => {
        this.$refs.topObserver.validate({ silent: true });
      });
    }
  }

  @Watch('serverErrors', { deep: true })
  handleServerErrors(errors: types.ServerErrors) {
    this.setServerErrors(errors);
  }

  setServerErrors(errors: types.ServerErrors) {
    this.$refs.topObserver?.setErrors(errors);
    this.navigateToFirstError(errors);
  }

  @Watch('activeLink')
  setScrollTop() {
    const layout = document.querySelector('#fs-layout-main');
    if (layout) {
      layout.scrollTop = 0;
    }
  }

  navigateToFirstError(serverErrors?: types.ServerErrors) {
    if (serverErrors) {
      this.setErrorIndex(serverErrors);
    } else {
      // the observer internally has a 16ms debounce on setting
      // its errors property, and exposes no way of knowing when
      // it has been updated
      setTimeout(() => {
        this.setErrorIndex(this.$refs.topObserver.errors);
      }, 32);
    }
  }
  setErrorIndex(errors: Record<string, string | string[]>) {
    outer: for (let i = 0; i < this.value.length; i++) {
      const step = this.value[i];
      for (const field of step.fields) {
        const fieldErrors = errors[field.key];
        if (fieldErrors && fieldErrors.length) {
          this.activeIndex = i;
          break outer;
        }
      }
    }
  }

  getObservers() {
    const { observer } = this.$refs;
    return Array.isArray(observer) ? observer : [observer];
  }

  mounted() {
    // next tick didn't work here
    // and there's no clear way to know that
    // all of the validation layers are rendered and ready
    setTimeout(async () => {
      for (const obs of this.getObservers()) {
        await obs.validate({ silent: true });
      }
      this.$refs.topObserver.validate({ silent: true }).then(() => {
        if (Object.keys(this.serverErrors).length) {
          this.$refs.topObserver.setErrors(this.serverErrors);
        }
        this.observersReady = true;
      });
    });
  }

  validatedNavList(to: InstanceType<typeof ValidationObserver>): UITab[] {
    const o: UITab[] = [];
    this.navList.forEach((link, i) => {
      const prevLink = o[i - 1];
      let disabled = !!prevLink?.disabled;
      // never disable step 1
      if (prevLink && !disabled) {
        const prevStep = this.value[i - 1];
        const keys = prevStep.fields.map(f => f.key);
        // disable if any previous steps are invalid
        for (const key of keys) {
          if (!disabled && to.fields[key]?.invalid) {
            disabled = true;
          }
        }
      }
      o.push({
        ...link,
        disabled: this.debug ? false : disabled,
      });
    });

    return o;
  }

  observerHasErros(observer: VObs) {
    return Object.entries(observer.errors).some(([, errors]) => {
      return errors.length > 0;
    });
  }

  activeLink = this.value[0]?.nav;
  updateActiveLink(link: UITab) {
    if (this.debug) {
      this.activeLink = link;
    }
    // always let the user go back
    // it's annoying otherwise
    const i = this.navList.findIndex(l => l.key === link.key);
    const goingBack = i < this.activeIndex;
    if (goingBack) {
      this.activeLink = link;
      return;
    }

    const observer = this.getObservers()[this.activeIndex];
    const hasVisibleErrors = this.observerHasErros(observer);

    // keep them on the step if there are visible errors
    // but if it's the last step then let them go back anyway
    if (hasVisibleErrors) {
      return;
    }

    // if they are trying to go forward, make sure the whole step is valid
    observer.validate().then(isValid => {
      if (isValid) {
        this.activeLink = link;
      }
    });
  }

  get activeIndex() {
    return this.navList.findIndex(link => link.key === this.activeLink.key);
  }

  set activeIndex(i: number) {
    this.activeLink = this.navList[i];
  }

  get navList(): UITab[] {
    return this.value.map(step => ({ ...step.nav }));
  }

  isLastStep(index: number) {
    return index === this.navList.length - 1;
  }

  handleStep(i: number, isBack = false) {
    if (isBack) {
      if (i > 0) {
        this.updateActiveLink(this.navList[this.activeIndex - 1]);
      } else {
        this.$logger.error("Can't navigate back from first step");
      }
    } else {
      this.getObservers()
        [i].validate()
        .then(async (isValid: boolean) => {
          if (isValid) {
            const { fields } = this.value[i];
            const res = await runCustomValidators(fields);
            if (res.hasErr) {
              this.setServerErrors(res.serverErrors);
            } else {
              if (!this.isLastStep(i)) {
                this.activeIndex += 1;
              } else {
                this.$emit('submit');
              }
            }
          }
        });
    }
  }

  updateStepFields(fields: types.FormField[], index: number, observer?: VObs) {
    const updatedValue: types.FormWizardStep[] = [...this.value];
    const step = { ...updatedValue[index] };
    step.fields = fields;
    updatedValue[index] = step;
    this.$emit('input', updatedValue);
    // validating here because the behavior of dependent fields is stupid in vee-validate
    this.$nextTick(() => {
      observer?.validate({ silent: true });
    });
  }
}
